<template>
  <div class="groupBuyingOrder">
    <div class="flexbox flex-lr flex-align-center">
      <el-input v-model="form.groupOrderId" placeholder="订单编号" style="width:200px;" clearable></el-input>
      <el-select v-model="form.state" class="ml20" clearable placeholder="核销状态" style="width:150px;">
        <el-option v-for="item in stateOptions" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
       <!-- <el-select v-model="form.payState" class="ml20" clearable placeholder="支付状态" style="width:150px;">
        <el-option v-for="item in payMethodList" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select> -->
      <el-date-picker v-model="time" class="ml20 mr20" type="daterange" range-separator="至" start-placeholder="下单开始时间"
        end-placeholder="下单结束时间">
      </el-date-picker>
      <el-button type="primary" @click="search">筛选</el-button>
      <el-button type="info" @click="clear">清空</el-button>
       <PrevReclick >
         <el-button type="primary" class="sure" @click="excleVisible=true">导出数据</el-button>
          </PrevReclick>
    </div>
    <div class="orderDetail">
      <span>订单总金额：{{orderAmount}}</span>
      <span>订单笔数：{{orderNum}}</span>
    </div>
    <div class="tableBox">
      <el-table :data="tableData" style="width: 100%" border>
        <el-table-column prop="id" label="订单编号" align="center" width="200"></el-table-column>
        <el-table-column prop="shopName" label="店铺名称" align="center"></el-table-column>
        <el-table-column prop="contactTel" label="客户电话" align="center"></el-table-column>
        <el-table-column prop="createTime" label="下单时间" width="160" align="center">
          <template slot-scope="scope">
            <span>{{ $formatDate(scope.row.createTime, 'yyyy-MM-dd HH:mm:ss') }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="payMethod" label="支付方式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payMethod == 1">会员钱包支付</span>
            <span v-if="scope.row.payMethod == 2">微信支付</span>
            <span v-if="scope.row.payMethod == 3">钱包支付</span>
          
          </template>
        </el-table-column>
        <el-table-column prop="state" label="核销状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 1">待核销</span>
            <span v-if="scope.row.state == 2">已核销</span>
            <span v-if="scope.row.state == 6">已失效</span>
            <span v-if="scope.row.state == 5">已退款</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="state" label="退款状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.state == 5">已退款</span>
            <span v-else>--</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="realPrice" label="商品金额" align="center"></el-table-column>
        <el-table-column prop="orderAmount" label="订单金额（元）" align="center"></el-table-column>
        <el-table-column prop="spreadFinance" label="平台抽佣金额" align="center"></el-table-column>
        <el-table-column prop="shopFinance" label="商户实得收益" align="center"></el-table-column>
      </el-table>
    </div>
    <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
    </el-pagination>
    <el-dialog title="导出数据" :visible.sync="excleVisible" width="400px" >
      <span style="padding-right:10px">选择日期：</span>
      <el-date-picker v-model="excleTime"  type="daterange" range-separator="至" start-placeholder="开始日期"
        end-placeholder="结束日期" :picker-options="pickerOptions">
      </el-date-picker>
      <p style="padding-top:20px;color:red">注：不能跨月导出数据</p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="excleVisible = false">取 消</el-button>
         <PrevReclick>
        <el-button type="primary" @click="expootExcle">确认导出</el-button>
         </PrevReclick>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  $sg_getGroupOrderList, $sg_getGroupBuyAmount,
} from '@/api/shopGroups';

import { exportFileSync } from '@base/utils';

export default {
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          // 获取2020年12月31日的时间戳
          const startTime = new Date('2020-12-31').getTime();
          // 获取目前时间的时间戳
          const endTime = new Date().getTime();
          // 禁止选择小于2021年1月1日之前和当前日期之后的日期
          return time.getTime() < startTime || time.getTime() > endTime;
        },
      },
      form: {
        groupOrderId: '',
        state: '',
        distributionPlatform: '',
        payState: '',
      },
      time: '',
      totalPage: 1,
      currentPage: 1,
      tableData: [],
      stateOptions: [
        {
          value: 1,
          label: '待核销',
        },
        {
          value: 2,
          label: '已核销',
        },
        {
          value: 11,
          label: '已失效',
        },
        {
          value: 5,
          label: '已退款',
        },
      ],
      
      orderAmount: 0,
      orderNum: 0,
      excleVisible: false,
      excleTime: null,
    };
  },
  created() {
    this.getTableData();
  },
  methods: {
    expootExcle() {
      let startTime = null;
      let endTime = null;
      if (this.excleTime) {
        console.log(this.excleTime, '34566');
        startTime = this.excleTime[0];
        endTime = this.excleTime[1];
        if (startTime.getMonth() !== endTime.getMonth() || startTime.getFullYear() !== endTime.getFullYear()) {
          this.$message.error('不能跨月导出数据');
          return;
        } else {
          startTime = (this.excleTime[0]).getTime() / 1000 || null; 
          endTime = (this.excleTime[1]).getTime() / 1000 || null;
        }
      } else {
        this.$message.error('请选择时间');
        return;
      }
      exportFileSync(
        '/api/agent/biz/exportOrders/groupOrders',
        {
          startTime,
          endTime,
        },
        'post',
        '团购订单.xlsx',
        this.onDownloadProgress,
      ).then(() => {
        // this.handleDownloadQrCodeLeftBtn();
      });
    },
    // 统计数据 
    getGroupBuyAmount(params = {}) {
      $sg_getGroupBuyAmount(params).then((res) => {
        this.orderAmount = res.groupBuyAmount || 0;
        this.orderNum = res.groupBuyOrderCount || 0;
      });
    },
    // 筛选
    search() {
      this.currentPage = 1;
      this.getTableData();
    },
    // 清空
    clear() {
      this.form = {
        groupOrderId: '',
        state: '',
        distributionPlatform: '',
        payState: '',
      };
      this.time = '';
      this.getTableData();
    },
    // 请求列表数据
    getTableData() {
      const params = {
        pageCurrent: this.currentPage,
        pageSize: 20,
        ...this.form,
      };
      if (this.time) {
        // if (this.time[0].getFullYear() !== this.time[1].getFullYear() || this.time[0].getMonth() !== this.time[1].getMonth()) {
        //   this.$message.error('查询时间必须在同一个月内');
        //   return;
        // }
        params.startTime = (this.time[0]).getTime() / 1000;
        params.endTime = (this.time[1]).getTime() / 1000 + (24 * 60 * 60 - 1);
      } else {
        params.startTime = '';
        params.endTime = '';
      }
      // 请求订单数据
      $sg_getGroupOrderList(params).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
     
      $sg_getGroupBuyAmount(params).then((res) => {
        this.orderAmount = res.groupBuyAmount || 0;
        this.orderNum = res.groupBuyOrderCount || 0;
      });
    },
    // 翻页
    pageChange() {
      this.getTableData();
    },
  },
};
</script>
<style lang="scss" scoped>
.groupBuyingOrder {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 40px 20px;
  box-sizing: border-box;
  background-color: #fff;
  .orderDetail {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #f0f0f0;
    font-size: 14px;
    color: #555;
    span:first-child {
      margin-right: 80px;
    }
  }
  .tableBox {
    width: 100%;
    margin-top: 40px;
  }
  .el-table {
    min-height: 580px;
    & > th {
      background-color: #ef3f46;
      color: #fff;
    }
  }
}
</style>

